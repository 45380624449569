import { useTranslation } from "react-i18next";
import Card from "./Card";
function Header() {
  const { t } = useTranslation();
  return (
    <div className="container col-md-4 mb-2">
      <h2 className="main-title text-center"> {t("about")}</h2>
      <Card text={t("about-text")} />
      <Card text={t("about-text2")} />
      <Card text={t("about-text3")} />
    </div>
  );
}
export default Header;
