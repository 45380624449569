function Contact() {
  return (
    <div className="container contact">
      <h2 className="main-title text-center">CONTACT</h2>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d752.8840923694265!2d28.672092!3d40.991632!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55f7ff80c60ab%3A0xfe4fb9988349d13b!2sCandy%20Tekstil%20Ltd.!5e0!3m2!1str!2sus!4v1692103502902!5m2!1str!2sus"
        style={{ width: "80%", height: "300px" }}
      ></iframe>
      <div className="contact-info">
        <h3 className="contact-text" style={{ textAlign: "center" }}>
          <i
            className="fa fa-phone fa-rotate-90"
            aria-hidden="true"
            style={{ marginRight: "1rem", color: "#01bf71" }}
          ></i>
          <a
            href="tel:+02125478161"
            style={{ textDecoration: "none", color: "#01bf71" }}
          >
            02125478161
          </a>
        </h3>
        <h3 className="contact-text" style={{ textAlign: "center" }}>
          <i
            className="fa fa-envelope"
            aria-hidden="true"
            style={{ marginRight: "1rem", color: "#01bf71" }}
          ></i>
          <a
            href= "mailto:fatih@candytekstil.com"
            style={{ textDecoration: "none", color: "#01bf71" }}
          >
            fatih@candytekstil.com
          </a>
        </h3>
      </div>
    </div>
  );
}
export default Contact;
