import { animateScroll as scroll } from 'react-scroll';

function Footer() {


  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <h1 className="logo">Candy Tekstil</h1>
                <p className="footer-text">
                  Yakuplu
                  84. Sk. No:4
                  34524 Beylikdüzü Osb/Beylikdüzü/İstanbul
                  Türkiye
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"><i className="fas fa-level-up-alt"></i></button>

    </footer>
  );
}
export default Footer;
