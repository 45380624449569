import { useTranslation } from "react-i18next";
import { Link, animateScroll as scroll } from "react-scroll";
const Navbar = () => {
  const { t, i18n } = useTranslation();
  const languages = { en: "Türkçe", tr: "English" };
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });
  function openBar() {
    const bar = document.querySelector(".bar");

    bar.classList.toggle("opened");
  }
  const setLang = () => {
    i18n.changeLanguage(i18n.resolvedLanguage === "en" ? "tr" : "en");
  };
  return (
    <nav className="navbar">
      <div className="container">
        <div className="row">
          <div className="logo">
            <Link
              onClick={() => scroll.scrollToTop(2500)}
              spy={true}
              smooth={true}
              duration={1000}
              to="navbar navopened"
              style={{ cursor: "pointer" }}
            >
              <img
                alt="Candy Tekstil"
                src="./img/candy.png"
                className="img-fluid"
              />
            </Link>
          </div>
          <ul className="bar">
            <li>
              <Link
                onClick={() => scroll.scrollToTop(2500)}
                activeClass="active"
                spy={true}
                smooth={true}
                duration={1000}
                to="navbar navopened"
              >
                {t("about")}
              </Link>
            </li>
            <li>
              <Link
                onClick={openBar}
                activeClass="active"
                to="main"
                spy={true}
                smooth={true}
                duration={1000}
              >
                {t("products")}
              </Link>
            </li>
            <li>
              <Link
                onClick={openBar}
                to="contact"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
              >
                {t("contact")}
              </Link>
            </li>
            <li>
              <Link
                onClick={setLang}
                to=""
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
              >
                {languages[i18n.resolvedLanguage]}
              </Link>
            </li>
          </ul>
          <div className="button" onClick={openBar}>
            <div className="burger"></div>
            <div className="burger"></div>
            <div className="burger"></div>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
