import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import info from "../json/info.json"
const NUMBER_OF_IMAGES = 62;
const OFFSET = window.screen.width < 576 ? 1 : 3;

function Services() {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [firstEl, setFirstEl] = useState(1);
  const [lastEl, setLastEl] = useState(OFFSET);
  const prevClicked = () => {
    if (firstEl - OFFSET > 0) {
      setFirstEl(firstEl - OFFSET);
      setLastEl(firstEl - 1);
    }
  };
  const nextClicked = () => {
    if (lastEl + OFFSET >= NUMBER_OF_IMAGES) {
      setLastEl(NUMBER_OF_IMAGES);
    } else {
      setLastEl(lastEl + OFFSET);
    }
    setFirstEl(firstEl + OFFSET);
  };
  useEffect(() => {
    const images = [];
    for (let i = firstEl; i <= lastEl; i++) {
      // note: we are adding a key prop here to allow react to uniquely identify each
      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
      images.push(
          <img
            key={i}
            alt={info[i-1]}
            loading="lazy"
            src={"gallery/" + i + ".jpg"}
            className="image-photo"
          />
      );
    }
    setRows(images);
  }, [setRows, firstEl, lastEl]);
  return (
    <div className="container services">
      <h2 className="main-title text-center"> {t("products")}</h2>
      <div className="image-row">
        <div className="image-column nature">
          <div className="image-content">
            <div
              style={{ display: firstEl === 1 ? "none" : "block" }}
              className="prev"
              onClick={prevClicked}
            >
              ❮
            </div>
            {rows}
            <div
              style={{
                display: lastEl === NUMBER_OF_IMAGES ? "none" : "block",
              }}
              className="next"
              href="s"
              onClick={nextClicked}
            >
              ❯
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
